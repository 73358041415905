<script lang="ts" setup>
import { toast } from "vue3-toastify";

const { t } = useT();
const { isOpen } = useAppModals();
const { initAndOpen } = useSupportChat();
const { isDesktop } = useDevice();

const toastOptions = {
	theme: toast.THEME.DARK,
	position: isDesktop ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.TOP_CENTER
};
const {
	unreadNotifications,
	readNotifications,
	isActiveAnimation,
	setNotificationsStatusAsRead,
	updateNotificationsStatusAsRead,
	handleClickNotificationMessage
} = useNotificationCenter({ isOpen, toastOptions });

const navigation = document.querySelector(".mob-navigation") as HTMLDivElement;
const widgets = document.querySelector(".widgets-fixed-side") as HTMLDivElement;

const counterNotification = computed(() =>
	unreadNotifications.value?.length > 9 ? "9+" : unreadNotifications.value?.length
);

const handleClickOpenSupport = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "widget",
		button_name: "support"
	});
	initAndOpen();
};
const handleOpenNotify = () => {
	if (document?.body) {
		document.body.style.overflow = "hidden";
		navigation.style.zIndex = "2002";
		widgets.style.zIndex = "2005";
	}
};

const handleCloseNotify = () => {
	if (document?.body) {
		document.body.style.overflow = "initial";
		navigation.style.zIndex = "";
		widgets.style.zIndex = "";
	}
};

onMounted(() => {
	if (!unreadNotifications.value?.length) {
		return;
	}
	const unreadOfferIds = unreadNotifications.value?.reduce<number[]>((acc, item) => {
		if (item.type === "offer" && new Date(item.dateEnd).getTime() < new Date().getTime()) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadOfferIds.length) {
		setNotificationsStatusAsRead(unreadOfferIds);
	}
});
onUnmounted(() => {
	if (!unreadNotifications.value?.length) {
		return;
	}
	const unreadIds = unreadNotifications.value?.reduce<number[]>((acc, item) => {
		if (!item.link) {
			acc.push(item.ncMessageId);
		}
		return acc;
	}, []);
	if (unreadIds.length) {
		updateNotificationsStatusAsRead(unreadIds);
	}
});
</script>

<template>
	<APopper placement="bottom-end" offsetDistance="20" @open:popper="handleOpenNotify" @close:popper="handleCloseNotify">
		<div :class="['notification-center-icon', { active: unreadNotifications.length }]" data-tid="notification-center">
			<ASvg :class="{ bell: isActiveAnimation }" name="notification-center/bell" />
			<ABadge v-if="unreadNotifications?.length" background="var(--caracas)" :size="14" class="counter">
				<AText variant="ternopil" :modifiers="['semibold', 'uppercase']">{{ counterNotification }}</AText>
			</ABadge>
		</div>
		<template #content="{ close }">
			<div class="notification-center-popper">
				<header>
					<div>
						<AText class="text-cannes" variant="tanta" :modifiers="['bold']">{{ t("InBox") }}</AText>
						<AText v-if="unreadNotifications?.length" class="count text-caracas" :modifiers="['medium']">{{
							unreadNotifications.length
						}}</AText>
					</div>
					<div class="close" @click="close">
						<ASvg name="notification-center/close" />
					</div>
				</header>
				<div :class="['content', { empty: !unreadNotifications.length && !readNotifications.length }]">
					<template v-if="unreadNotifications.length || readNotifications.length">
						<div v-if="unreadNotifications.length" class="new">
							<div class="header">
								<AText class="text-catar title" :modifiers="['bold']" as="div">{{ t("New") }}</AText>
								<ADivider bg-color="var(--chandigarh)" />
							</div>
							<ONotificationCenterMessage
								v-for="message in unreadNotifications"
								:key="`unread-${message.ncMessageId}`"
								:message="message"
								@click-on-message="handleClickNotificationMessage"
							/>
						</div>
						<div v-if="readNotifications.length" class="earlier">
							<div class="header">
								<AText class="text-catar title" :modifiers="['bold']" as="div">{{ t("Earlier") }}</AText>
								<ADivider bg-color="var(--chandigarh)" />
							</div>

							<ONotificationCenterMessage
								v-for="message in readNotifications"
								:key="`read-${message.ncMessageId}`"
								:message="message"
								@click-on-message="handleClickNotificationMessage"
							/>
						</div>
					</template>
					<template v-else>
						<div class="icon">
							<ASvg name="notification-center/info" />
						</div>
						<AText as="p" class="title" variant="taipei" :modifiers="['center', 'bold']">{{
							t("You don’t have any new notifications")
						}}</AText>
						<AText variant="toledo" :modifiers="['center']" as="p">{{
							t("Messages older than 14 days are deleted.")
						}}</AText>
					</template>
				</div>
				<footer v-if="unreadNotifications.length || readNotifications.length">
					<AText as="div" class="text-cannes">{{ t("Messages older than 14 days are deleted.") }}</AText>
					<AText as="div" :modifiers="['link', 'underline', 'semibold']" @click="handleClickOpenSupport">
						{{ t("Customer Care") }}
					</AText>
				</footer>
			</div>
		</template>
	</APopper>
</template>

<style scoped lang="scss">
:deep(.hidden) {
	overflow: hidden;
}
.notification-center {
	&.in-menu {
		@include media-breakpoint-down(lg) {
			display: none;
		}
		.content {
			max-height: 50dvh;
		}
	}

	&.out-of-menu {
		display: none;
		right: 0;
		z-index: 200;

		@include media-breakpoint-down(lg) {
			display: block;

			@media (orientation: landscape) {
				bottom: 104px;
			}
		}

		.content {
			height: calc(100dvh - 127px);
			padding-bottom: 12px;
			max-width: 100%;

			@include media-breakpoint-down(lg) {
				@media (orientation: landscape) {
					height: calc(100dvh - 107px);
					padding-bottom: 16px;
				}
			}
		}
		:deep(.popper) {
			--popper-theme-border-width: 0;
			--popper-theme-border-radius: 0;
			--popper-theme-box-shadow: none;
			transform: none !important;
			max-width: 100vw;
			width: 100%;
			position: fixed !important;
			top: 0 !important;
			right: 0;
			height: calc(100vh - 64px);
			z-index: 2001;

			@include media-breakpoint-down(lg) {
				height: 100dvh;
			}
		}
	}
}
.notification-center-icon {
	width: 54px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 8px 16px;
	font-size: 22px;
	border: 2px solid var(--chandigarh);
	border-radius: 999px;
	background: var(--craiova);
	cursor: pointer;

	&.active {
		box-shadow: 0 0 20px 0 var(--caracas);
	}

	@include media-breakpoint-down(lg) {
		width: 52px;
		height: 52px;
		padding: 12px 8px 12px 16px;
		border-radius: 12px 0 0 12px;
		border-right: 0;
		font-size: 24px;

		&.active {
			box-shadow: 0 0 10px 0 rgba(var(--cofeea-rgb), 0.9);
		}
	}

	.bell {
		transform-origin: top center;
		animation: bell 6s 0.5s ease-in-out infinite;
	}

	.counter {
		position: absolute;
		top: 2px;
		right: 10px;
		width: 14px;
		height: 14px;
		display: flex;
		color: var(--cheng);
		line-height: 14px;
		text-shadow:
			0 1px 3px rgba(var(--cusco-rgb), 0.1),
			0 1px 2px rgba(var(--cusco-rgb), 0.06);

		@include media-breakpoint-down(lg) {
			right: 6px;
			top: 6px;
		}
	}
}

.notification-center-popper {
	min-width: 360px;
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 12px;

	@include media-breakpoint-down(lg) {
		height: 100dvh;

		@media (orientation: landscape) {
			width: 100%;
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			bottom: 56px;
			left: 0;
			right: 0;
			height: 10px;
			background: var(--popper-theme-background-color);

			@media (orientation: landscape) {
				content: none;
			}
		}
	}

	header {
		padding: 16px 16px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		> div {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}

	.content {
		max-width: 360px;
		padding: 0 16px;
		overflow-y: auto;
		@include scrollbar(4px, 4px, var(--caracas), transparent, transparent);

		&.empty {
			min-height: 390px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 16px;

			.title {
				line-height: 30px;
			}

			@include media-breakpoint-down(lg) {
				@media (orientation: landscape) {
					min-height: calc(100dvh - 40px);
				}
			}
		}

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 4px;
		}

		.message {
			padding: 12px;
			border-radius: 12px;
			border: 2px solid var(--chandigarh);
			background: var(--craiova);
		}

		.new .message {
			cursor: pointer;
			box-shadow: 0 30px 40px -10px var(--cincinnati);
		}
		.earlier {
			margin-top: 16px;
			.message {
				background: var(--cheng);
			}
		}
		.icon {
			width: 64px;
			height: 64px;
			padding: 8px;
			margin-bottom: 8px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 64px;
			background: var(--craiova);
		}
	}

	footer {
		padding: 16px;
		background: var(--chattogram);
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
		border-radius: 0 0 16px 16px;

		@include media-breakpoint-down(lg) {
			border-radius: 0;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;

			@media (orientation: landscape) {
				bottom: 0;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
			}
		}

		&:deep(.link) {
			line-height: 22px;
		}
	}

	.count {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 22px;
		border-radius: 4px;
		background: var(--ceuta);
	}

	.close {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		border-radius: 50%;
		color: var(--cannes);
		cursor: pointer;
		background: rgba(var(--cesena-rgb), 0.1);
	}
}

:deep(.popper) {
	--popper-theme-background-color: var(--cambe);
	--popper-theme-background-color-hover: var(--cambe);
	--popper-theme-box-shadow: 0 0 20px 0 var(--caracas);
	--popper-theme-padding: 0;
	--a-popper-default-font-weight: 400;
	--a-popper-default-border-radius: 16px;
}

@keyframes bell {
	0% {
		transform: rotate(0);
	}
	1% {
		transform: rotate(30deg);
	}
	3% {
		transform: rotate(-28deg);
	}
	5% {
		transform: rotate(34deg);
	}
	7% {
		transform: rotate(-32deg);
	}
	9% {
		transform: rotate(30deg);
	}
	11% {
		transform: rotate(-28deg);
	}
	13% {
		transform: rotate(26deg);
	}
	15% {
		transform: rotate(-24deg);
	}
	17% {
		transform: rotate(22deg);
	}
	19% {
		transform: rotate(-20deg);
	}
	21% {
		transform: rotate(18deg);
	}
	23% {
		transform: rotate(-16deg);
	}
	25% {
		transform: rotate(14deg);
	}
	27% {
		transform: rotate(-12deg);
	}
	29% {
		transform: rotate(10deg);
	}
	31% {
		transform: rotate(-8deg);
	}
	33% {
		transform: rotate(6deg);
	}
	35% {
		transform: rotate(-4deg);
	}
	37% {
		transform: rotate(2deg);
	}
	39% {
		transform: rotate(-1deg);
	}
	41% {
		transform: rotate(1deg);
	}
	43% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(0);
	}
}
</style>
<style lang="scss">
#notification-center-container,
#notification-center-container-email {
	@include media-breakpoint-down(lg) {
		bottom: auto;
		top: 64px;
		z-index: 100;
	}

	.Toastify {
		&__toast-icon {
			width: 40px;
			margin-right: 8px;

			img {
				width: 40px;
				height: 40px;
			}
		}

		&__close-button {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 20px;
			min-height: 20px;
			opacity: 1;
			color: var(--cannes);
			cursor: pointer;
			background: rgba(var(--cesena-rgb), 0.2);
			border-radius: 50%;
			position: relative;

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(calc(-50% + 1px), -50%);
				width: 12px;
				height: 12px;
			}
		}

		&__toast {
			padding: 12px;
			margin-bottom: 12px;
			border-radius: 12px;
			box-shadow: 0 30px 40px -10px var(--cincinnati);
			border: 2px solid var(--chandigarh);
			background: var(--craiova);
		}

		&__toast-body {
			align-items: flex-start;
			max-width: 280px;
			font-size: 14px;
			white-space: normal;

			.notification-title {
				font-weight: 600;
				line-height: 16px;
				color: var(--cesena);
			}

			.notification-text {
				margin: 4px 0 0 0;
				line-height: 1.2;
				color: var(--cairo);
			}
		}
	}
}

#notification-center-container-email {
	@include media-breakpoint-down(lg) {
		top: 136px;

		@media (orientation: landscape) {
			top: 100px;
		}
	}
}
</style>
